import { Card } from "@components/cards/card";
import { useEffect, useState } from "react";

import { api } from "@service/api";
import dayjs from "dayjs";

import { ConfirmButton } from "@components/buttons/confirm-button";
import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IWithdrawsPaymentDTO } from "@hooks/dtos/IWithdrawDto";
import { IManagerSecretDTO } from "@hooks/dtos/manager/IManagerDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { FaCircleUp, FaCircleXmark } from "react-icons/fa6";

const status = {
    s: {
        color: "success",
        text: "Enviado"
    },
    p: {
        color: "success",
        text: "Aprovado",
    },
    w: {
        color: "warning",
        text: "Pendente",
    },
    x: {
        color: "danger",
        text: "Cancelado",
    },
}

const masks = new Masks();
export function ApprovalWithdrawCashManual() {
    const [isLoading, setIsLoading] = useState(true);
    const { actions: { pushToastify } } = ZuToastifyStore()
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [withdraws, setWithdraws] = useState<IWithdrawsPaymentDTO[]>([]);
    const [search, setSearch] = useState('');

    async function historicDeposits(page: number, search: string = '') {
        try {
            const status = "w";
            const response = await api.post(`/manager/cash/payout/pagination`, {
                search: search,
                page: page,
                status
            });
            setWithdraws(response.data.result);
            setTotalPages(response.data.pages);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    };

    async function onApproveCashWithdraw(withdrawId: number, secrets: IManagerSecretDTO /* Pin & OTP */) {
        try {
            const response = await api.post(`/manager/cash/payout/approve/manual`, {
                withdrawId,
                pin: secrets?.pin,
                otpAuth: secrets?.otp
            });

            pushToastify({
                message: `Aprovação efetuada com sucesso, a solução de pagamento responsável estará fazendo o restante.`,
                type: 'success',
                theme: 'dark',
            });

            setTimeout(() =>
                historicDeposits(page, search),
                1500
            );

        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    async function onRejectCashWithdraw(withdrawId: number, secrets: IManagerSecretDTO /* Pin & OTP */) {
        try {
            const response = await api.post(`/manager/cash/payout/reject`, {
                withdrawId,
                pin: secrets?.pin,
                otpAuth: secrets?.otp
            });

            pushToastify({
                message: `Cancelamento efetuado com sucesso, o saldo do Usuário foi atualizado de ${masks.brlCurrency(response?.data.balanceBefore)} para ${masks.brlCurrency(response?.data.balance)}.`,
                type: 'success',
                theme: 'dark',
            });

            setTimeout(() =>
                historicDeposits(page, search),
                1500
            );

        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    useEffect(() => {
        historicDeposits(page);
    }, [page]);

    useEffect(() => {
        setIsLoading(true);
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            historicDeposits(1, search);
        }, 2500);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    return (
        <div className="row">
            <div className="col-md-12">
                <Card className="card-round">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <h4 className="card-title">Solicitações de Saque</h4>
                                <p className="card-category">Lista de todas solicitações de saques em aberto.</p>
                            </div>
                            <div className="col-md-2">
                                <InputSearch animated label="Buscar" placeholder="" value={search} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div id="basic-datatables_length" className="dataTables_length">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr role="row">
                                                <th scope="col" className="fs-6">#</th>
                                                <th scope="col" className="fs-6">Usuário</th>
                                                <th scope="col" className="fs-6">Chave PIX</th>
                                                <th scope="col" className="fs-6">Valor Solicitado</th>
                                                <th scope="col" className="fs-6">Taxa</th>
                                                <th scope="col" className="fs-6">Valor à Pagar</th>
                                                <th scope="col" className="fs-6">Status</th>
                                                <th scope="col" className="fs-6">Data</th>
                                                <th scope="col" className="fs-6">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <LoadingSkeleton />
                                                    </td>
                                                </tr>
                                            ) : withdraws.length > 0 ? (
                                                withdraws.map((withdraw, index) => (
                                                    <tr key={index}>
                                                        <td>{withdraw.id}</td>
                                                        <td><a href={`/user/info/${withdraw.customer}`}>{withdraw.customerData.userName}</a> (#{withdraw.customer})</td>
                                                        <td>{withdraw.pixKey}</td>
                                                        <td>{masks.brlCurrency(withdraw.value)}</td>
                                                        <td>{masks.brlCurrency(withdraw.fee + withdraw.gatewayFee)}</td>
                                                        <td>{masks.brlCurrency(withdraw.value - withdraw.fee - withdraw.gatewayFee)}</td>
                                                        <td>
                                                            <span className={`fs-6 badge badge-${status[withdraw.status].color}`}>
                                                                {status[withdraw.status].text}
                                                            </span>
                                                        </td>
                                                        <td>{withdraw.dateRequest ? dayjs(withdraw.dateRequest).format('DD/MM/YYYY HH:mm:ss') : '~'}</td>
                                                        <td className="d-flex flex-md-row flex-column align-items-center justify-content-start gap-2">
                                                            <ConfirmButton
                                                                title={<FaCircleUp />}
                                                                className={`btn fs-5 text-success p-0`}
                                                                validations="doubleCheck"
                                                                textConfirmation={`Por favor, insira sua senha e o OTP para APROVAR a solicitação de saque do Usuário @${withdraw.customerData.userName} (#${withdraw.customer}) no valor de ${masks.brlCurrency(withdraw.value)}!`}
                                                                needConfirm={true}
                                                                onConfirm={(f, data) => {
                                                                    if (f === true) {
                                                                        onApproveCashWithdraw(Number(withdraw?.id), data as IManagerSecretDTO);
                                                                    }
                                                                }}
                                                            />
                                                            <ConfirmButton
                                                                title={<FaCircleXmark />}
                                                                className={`btn fs-5 text-danger p-0`}
                                                                validations="doubleCheck"
                                                                textConfirmation={`Por favor, insira sua senha e o OTP para REPROVAR a solicitação de saque do Usuário @${withdraw.customerData.userName} (#${withdraw.customer}) no valor de ${masks.brlCurrency(withdraw.value)}!`}
                                                                needConfirm={true}
                                                                onConfirm={(f, data) => {
                                                                    if (f === true) {
                                                                        console.log(`That's right`, data, withdraw);
                                                                        onRejectCashWithdraw(Number(withdraw?.id), data as IManagerSecretDTO);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                    </div>
                </Card>
            </div>
        </div>
    );
}
